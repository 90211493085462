<template>
  <div class="pageMain permis-main">
    <div class="permis-left">
      <div class="title">角色权限</div>
      <div class="left-body">
        <div  v-for="item in roleList"
              :class="['role-item',{'active':activeRole == item.roleId}]"
              :key="item.roleId" @click="changeRole(item)">
          {{item.roleName}}
        </div>
      </div>
    </div>
    <div class="permis-right">
      <div class="right-title">
        <el-row>
          <el-col :span="12">
            <div class="title">
              <i class="el-icon-s-operation"></i>功能权限&nbsp;
              <!--        <el-checkbox v-model="checkedAll" @change="handleCheckAllChange" >全选</el-checkbox>-->
            </div>
          </el-col>
          <el-col :span="12" class="right">
            <el-button size="small" type="primary" plain @click="savePermiss">保存</el-button>
          </el-col>
        </el-row>
      </div>

      <div class="right-body">
        <div class="module-type-main">
          <role-permiss-tree v-for="item in menuList" :key="item.moduleType" :item="item" :checked="checkedObj"
                             is-checked="true"></role-permiss-tree>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {mapState} from 'vuex'
import RolePermissTree from "../../../components/page/basic/RolePermissTree";
import rights from "../../../api/modules/rights";

export default {
  name: "rolePermission",
  components: {RolePermissTree},
  data() {
    return {
      activeRole:'',
      roleList:[],
      checkedAll: false,
      checkedlist: [],
      menuList: [],
      checkedObj:{}
    }
  },
  computed:{
    ...mapState({
      roleCheckboxList: state => state.rights.roleCheckboxList
    })
  },
  mounted() {
    this.getTypeAndChildren()
    this.getRoleInfo()
    // 清空
    this.$store.dispatch('rights/clearCheckMenu')
  },
  methods: {
    getRoleInfo(){
      rights.getRoleList().then(res =>{
        this.roleList = res.data
        this.activeRole = res.data[0].roleId
        this.getPermissInfo()
      })
    },
    getTypeAndChildren(){
      rights.getTypeAndChildren().then(res =>{
        this.menuList = res.data

      })
    },
    getPermissInfo(){
      this.checkedObj ={}
      // 获取所属权限
      rights.getRoleModuleList({roleId:this.activeRole}).then(res =>{
        console.log(res)
        let rightObj = {}
        res.data.forEach(item =>{
          if(item.parentId == 0){
            rightObj[item.moduId] = {
              moduId: item.moduId,
              children: [],
              checked: true
            }
          }else {
            // 菜单
            if(rightObj[item.parentId]){
              rightObj[item.parentId].children.push(item.moduId)
            }else {
              rightObj[item.parentId] = {
                moduId: item.parentId,
                children:[item.moduId],
                checked: false
              }
            }
          }
        })
        for(let key in rightObj){
          this.$store.dispatch('rights/setCheckMenu',rightObj[key])
        }
        console.log('rightObj', rightObj)
        this.checkedObj = rightObj
      })

    },
    changeRole(item){
      this.activeRole = item.roleId
      // 清空
      this.$store.dispatch('rights/clearCheckMenu')
      this.getPermissInfo()
    },
    savePermiss(){
      // 保存权限
      let rightList = []
      this.roleCheckboxList.forEach(item =>{
        if(item.children && item.children.length > 0){
          item.children.forEach(child =>{
            rightList.push(child)
          })
        }else {
          if(item.checked){
            rightList.push(item.moduId)
          }
        }
      })
      rights.addRoleAuth({roleId: this.activeRole, roleModuIds: rightList}).then(res =>{
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.getPermissInfo()
      })


    },
    handleCheckAllChange() {

    }
  }
}
</script>

<style scoped lang="less">
@import "../../../assets/css/selfcheck.less";


</style>