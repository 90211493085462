<template>
  <div class="module-type-item">
    <div class="type-item-box">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"  @change="handleCheckAllChange">{{ item.moduName }}</el-checkbox>
    </div>
    <el-checkbox-group class="module-item-main" v-model="checkedlist" @change="handleCheckedListChange">
      <el-row>
        <el-col :span="6" class="module-item" v-for="child in item.children" :key="child.moduId">
          <el-checkbox :label="child.moduId">{{ child.moduName }}</el-checkbox>
        </el-col>
      </el-row>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "RolePermissTree",
  props: ['item','checked'],
  watch:{
    checked(newVal,oldVal){
      let objInfo = newVal[this.item.moduId]
      if(objInfo){
        this.checkAll = objInfo.checked ? objInfo.checked : objInfo.children.length == this.item.children.length
        this.checkedlist = objInfo.children
        this.isIndeterminate = objInfo.children.length < this.item.children.length
      }else {
        this.checkAll = false
        this.checkedlist = []
        this.isIndeterminate = false
      }
    }
  },
  data() {
    return {
      checkedlist: [],
      allMenu:[],
      isIndeterminate: false,
      checkAll: false
    }
  },
  mounted() {
    this.allMenu = []
    this.item.children.forEach(item =>{
      this.allMenu.push(item.moduId)
    })
  },
  methods: {
    handleCheckAllChange(val){
      this.checkedlist = val ? this.allMenu :[]
      this.isIndeterminate = false
      let data = {
        moduId: this.item.moduId,
        children: this.checkedlist,
        checked: val
      }
      this.$store.dispatch('rights/setCheckMenu',data)
    },
    handleCheckedListChange(val) {
      let checkedCount = val.length;
      this.checkAll = checkedCount === this.allMenu.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.allMenu.length;
      let data = {
        moduId: this.item.moduId,
        children: val,
        checked: this.checkAll
      }
      this.$store.dispatch('rights/setCheckMenu',data)
    },
  }

}
</script>

<style scoped lang="less">
.module-type-item {
  font-size: 18px;
  height: 120px;
  border: 1px solid #f0f0f0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;

  .type-item-box {
    width: 120px;
    line-height: 80px;
    height: calc(100% - 40px);
    text-align: center;
    padding: 20px;
    display: flex;
    border-right: 1px solid #f0f0f0;
  }

  .module-item-main {
    width: 100%;
    flex: 1;
    padding: 10px;
    .module-item{
      margin-bottom: 10px;
    }
  }
}
</style>